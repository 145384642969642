.products-slider-container {
  height: 33.9vw;
  margin: 0 3.125% ;
  position: relative;
}

.product-slide {
  width: 100%;
  /* height: 100%; */
  position: relative;
  opacity: 0;
  transition: opacity ease-in-out 0.4s;
}

.product-slide img {
  width: 100%;
  height: 40vw;
  position: absolute;
  border-radius: 40px;
  object-fit: cover;
  padding-top: 10%;
}

.active-anim {
  opacity: 1;
}


@media screen and (max-width: 1024px){
  .products-slider-container {
    height: 50vw;
    margin: 0;
    border-radius: 0;
  }
  .products-slide {
    height: 100%;
    position: absolute;
  }
  .products-slide img {
    height: 100%;
    border-radius: 0;
  }
}

@media only screen and (max-width: 720px) {       
  .products-slider-container {
    height: 80vw;
    margin: 0;
    border-radius: 0;
  }
  .product-slide img {
    height: 75vw;
    position: absolute;
  }
}