.sidebar {
    width: 100%;
    position: sticky;
    top: 50px;
    transition: all .5s ease-in-out;
    z-index: 2;
    background-color: aliceblue;
    border-radius: 20px;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight: 550;
    
}

.sidebar-wrapper {
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
    box-shadow: 0 0 13px rgb(0 0 0 / 5%);
    border-radius: 10px;
}

.sidebar-overly {
    left: 0
}

.sidebar-avatar {
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    padding-top: 25px;
}

.avatar-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.avatar {
    width: 120px;
    height: 120px;
    border-radius: 100%;
    background-image: url('../../../assets/avatar-profile.png');
}

.avatar-name {
    font-size: 14px;
    text-align: center;
    display: block;
    margin-top: 25px;
    margin-bottom: 25px;
    color: #707070;
}

.avatar-image {
    width: 100%;
    height: 100%;
    position: relative;
    overflow: hidden;
}

.list-menu {
    padding: 0 25px 4px 0;
}

.list-menu-item {
    cursor: pointer;
    margin-bottom: 20px;
    display: block;
}

.list-menu-content {
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    position: relative;
    text-decoration: none;
}

.list-menu-content.active::before {
    content: "";
    width: 2px;
    border-radius: 5px;
    left: 0;
    top: 0;
    bottom: 0;
    background-color: #5098ef;
    position: absolute;
}

.list-menu-icon {
    margin-left: 10px;
    position: relative;
}

.list-menu-icon img {
    transition: all .3s ease-in;
}

.list-menu-label {
    color: #a4a4a4;
}

.list-menu-label.active {
    color: #151515;
}

@media (max-width: 991.98px) {
    .sidebar-overly {
        position: sticky;
        top: 0;
        bottom: 0;
        right: 0;
        z-index: -1;
    }
}