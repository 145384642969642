.vidobo-modal-background {
    height: 100%;
    width: 100%;
    background-color: rgba(0,0,0,.79);
    position: fixed;
    top: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 15px;
}

.vidobo-modal-container {
    width: 400px;
    height: auto;
    border-radius: 12px;
    background-image: linear-gradient(90deg,#e3eaf8,#fff);
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    display: flex;
    flex-direction: column;
    padding: 25px;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.vidobo-modal-container button {
    width: 20px;
    height: 20px;
}

.vidobo-modal-title {
    flex: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.4rem;
    text-align: center;
    color: #707070;
}

.modal-drop-down {
    border-radius: 25px;
    background-color: #E3EAF8;
    color: #707070;
    height: 40px;
    width: 200px;
    margin-bottom: 10%;
}

.vidobo-modal-footer {
    flex: 20%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.vidobo-modal-footer button {
    width: 150px;
    height: 45px;
    margin: 10px;
    border: none;
    background-image: linear-gradient(to right, #3B38C1 , #1B9FF1);
    color: white;
    border-radius: 8px;
    font-size: 20px;
    cursor: pointer;
}

.modal-error {
    color: red;
    height: 1rem;
    font-size: .986rem;
    text-align: center;
}

@media (max-width: 480px) {
    .vidobo-modal-container {
        width: 300px;
    }
}