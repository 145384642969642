.piano-container {
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 10px;
    height: 100vh;
    background-color: #143f6b;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}

.piano-controller {
    width: 100%;
    height: 50vh;
    direction: ltr;
    display: flex;
    align-items: center;
}

.piano-buttons-wrapper {
    width: 90%;
    height: 80%;
    direction: ltr;
    display: flex;
    margin: auto;
}

.song-control-ui {
    direction: ltr;
    width: 33.3%;
    display: flex;
    flex-direction: column;
}

.lcd-control-ui {
    width: 33.3%;
}

.challenge-control-ui {
    direction: ltr;
    width: 33.3%;
    display: flex;
    flex-direction: column;
}

.play-stop-song {
    direction: ltr;
    display: flex;
    width: auto;
    height: 50%;
}

.song-select {
    margin: auto;
}

.start-stop-challenge {
    direction: ltr;
    display: flex;
    width: auto;
    height: 50%;
}

.circular-progressbar-wrapper {
    position: relative;
    height: 50%;
    margin-left: auto;
    margin-right: auto;
}

.circular-progressbar {
    display: block;
    height: 100%;
    margin-left: auto;
    margin-right: auto;
}

.progress-percentage {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 60px;
    color: #ebac00;
    font-size: 1.5rem;
    margin: auto;
    text-align: center;
}

.progress-falsenotes {
    position: absolute;
    top: 100%;
    left: 100%;
    transform: translate(-50%, -50%);
    width: 60px;
    color: #00ff00;
    font-size: 1.5rem;
    margin: auto;
    text-align: center;
}

.wrongnotes {
    color: #ff0000;
}

.cls-1 {
    fill: none;
    stroke-miterlimit: 10;
    stroke-width: 60px;
    stroke: #f0f9fe;
}

.cls-2 {
    fill: none;
    stroke-miterlimit: 10;
    stroke-width: 60px;
    stroke: #77c8f0;
    stroke-linecap: round;
}

.piano-play-song {
    border: none;
    background-color: transparent;
    background-image: url('./Icons/StartButton/PlaySongButton.svg');
    background-position: center;
    background-repeat: no-repeat;
    width: 50%;
}

.piano-stop-song {
    border: none;
    background-color: transparent;
    background-image: url('./Icons/StopButton/StopSongButton.svg');
    background-position: center;
    background-repeat: no-repeat;
    width: 50%;
}

.piano-start-play {
    border: none;
    background-color: transparent;
    background-image: url('./Icons/StartChallenge/StartChallengeButton.svg');
    background-position: center;
    background-repeat: no-repeat;
    width: 50%;
}

.piano-stop-play {
    border: none;
    background-color: transparent;
    background-image: url('./Icons/StopChallenge/StopChallengeButton.svg');
    background-position: center;
    background-repeat: no-repeat;
    width: 50%;
}

.piano-song-select {
    width: 60px;
    color: #ebac00;
    font-family: Vazir;
    font-size: 1rem;
    margin-left: auto;
    margin-top: auto;
    margin-bottom: auto;
    text-align: center;
}

.loading-instrument {
    color: #fff;
    width: 20%;
    font-family: Vazir, sans-serif;
    text-align: center;
}

.piano {
    width: 100%;
    height: 50vh;
    margin: 0 0;
    justify-content: center;
    direction: ltr;
    display: flex;
}

.key {
    width: var(--width);
    height: var(--height);
}

.white {
    --width: 8%;
    --height: 100%;
    background-color: #FFF;
    border: 1px solid #333;
    border-radius: 0 0 20px 20px;
}

.white.active {
    background-color: #CCC;
}

.white.toPlay {
    background-color: lime;
}

.white.toPlay.active {
    background-color: limegreen;
}

.black.toPlayAb3 {
    background-color: #0B660B;
}

.black.toPlayAb3.active {
    background-color: #0B540B;
}

.white.toPlayA3 {
    background-color: lime;
}

.white.toPlayA3.active {
    background-color: limegreen;
}

.black.toPlayBb3 {
    background-color: #000080;
}

.black.toPlayBb3.active {
    background-color: #00006E;
}

.white.toPlayB3 {
    background-color: #1260C0;
}

.white.toPlayB3.active {
    background-color: #0F51A2;
}

.white.toPlayC4 {
    background-color: #FF60B4;
}

.white.toPlayC4.active {
    background-color: #E657A2;
}

.white.toPlayD4 {
    background-color: #87CEFA;
}

.white.toPlayD4.active {
    background-color: #77B5DC;
}

.white.toPlayE4 {
    background-color: #FFA000;
}

.white.toPlayE4.active {
    background-color: #D68700;
}

.white.toPlayF4 {
    background-color: #FC143C;
}

.white.toPlayF4.active {
    background-color: #E10529;
}

.white.toPlayG4 {
    background-color: #FFFF00;
}

.white.toPlayG4.active {
    background-color: #D6D600;
}

.white.toPlayA4 {
    background-color: lime;
}

.white.toPlayA4.active {
    background-color: limegreen;
}

.black {
    --width: 6%;
    --height: 58%;
    background-color: #000;
    margin-left: calc(var(--width)/(-2));
    margin-right: calc(var(--width)/(-2));
    z-index: 2;
    border-radius: 0 0 20px 20px;
}

.black.active {
    background-color: #333;
}

.black.toPlay {
    background-color: forestgreen;
}

.black.toPlay.active {
    background-color: green;
}