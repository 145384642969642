.loading-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    flex-direction: column;
    font-size: 1.2rem;
    font-weight: 700;
}

.loading-container img {
    width: 100px;
    height: 100px;
}