header {
    background-image: linear-gradient(90deg,#000048,#08002D);
    /* background-image: linear-gradient(90deg,#01116E,#000048); */
    color: #fff;
    font-size: 15px;
    align-items: center;
    z-index: 1;
    position: sticky;
    top: 0;
}

.header-container {
    display: flex;
    flex-direction: row;
    padding-left: 7.2%;
    padding-right: 7.2%;
}

.header-responsive-left {
    display: none;
}

.header-responsive-right {
    display: none;
}

.header-wrapper-left {
    width: 30%;
    display: flex;
    font-size: 15px;
    align-items: center;
}

.header-wrapper-right {
    width: 70%;
    display: flex;
    justify-content: start;
    align-items: center;
}

.header-item {
    display: flex;
    flex-direction: row;
    padding-inline-end: 35px;
}

.header-item:hover {
    color: #518ef7;
}

.header-item img {
    width: 35px;
    height: auto;
}

.products-button {
    padding: 5px 15px;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.products {
    padding-top: 10px;
    padding-bottom: 5px;
    text-decoration: none;
}

.products:hover {
    color: #518ef7;
}

.flag-img {
    height: 35px;
    margin: 3px;
}

.language {
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: row;
    /* color: #518ef7; */
    text-decoration: none;
}

@media only screen and (max-width: 834px){
    .header-container {
        padding-left: 9.9%;
        padding-right: 9.9%;
    }

    .header-responsive-left {
        direction: ltr;
        width: 11%;
        display: flex;
        align-items: center;
    }

    .header-responsive-right {
        direction: rtl;
        width: 89%;
        display: flex;
        align-items: center;
    }

    .menu-responsive-icon {
        display: flex;
        justify-content: left;
        align-items: center;
    }

    .menu-responsive-container {
        position: absolute;
        display: flex;
        justify-content: center;
        flex-direction: column;
        top: 35px;
        text-align: center;
        background-color: #3b38c1;
        /* background: #fff; */
        width: 130px;
        height: 180px;
        border-radius: 20px 20px;
    }

    .header-wrapper-left {
        display: none;
    }

    .header-wrapper-right {
        display: none;
    }

    .products-button {
        display: block;
    }

    .header-logo {
        font-size: larger;
        padding-left: 20px;
    }

    .products-button {
        padding-top: 0;
    }

    .button-aboutus {
        padding: 5px 5px;
        margin-bottom: 10px;
    }
    
    .button-login {
        padding: 5px 5px;
    }
}

