.join{
    z-index: 1;
    width: 100%;
    height: fit-content;
    height: -moz-max-content;
  }
  
  .join  input{
    padding: 15px;
    font-size: 1rem;
    border-width: 1px;
    border-color: #38373A;
    border-style: solid;
    width: 80%;
    display: block;
    margin:  50px auto;
  }
  
  .join  button{
    min-width: 200px;
    padding: 12px 0;
    text-align: center;
    background-color: #38373A;
    color: #F7F7F7;
    border-width: 1px;
    border-color: #F7F7F7;
    border-style: solid;
    font-size: 1rem;
    font-weight: 400;
    cursor: pointer;
    display: block;
    margin: 0 auto;
  }
  
  .test-table-wrapper {
    padding: 28px 25px;
  }
  
  .test-table {
      width: 100%!important;
      border: 0;
      border-collapse: collapse;
  }
  
  .test-table-header {
      height: 40px;
      border-radius: 10px;
  }
  
  .table-row {
    display: flex;
    flex-direction: row;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  
  .test-table-row {
      height: 50px;
      border-radius: 50px;
      box-shadow: 8px 12px 26px rgba(0, 0, 0, 0.12);
  }
  
  .test-table-row:hover {
    height: 50px;
    border-radius: 50px;
    background-color: #ccc7fe;
    box-shadow: 8px 12px 26px rgba(0, 0, 0, 0.12);
  }

  .users-control {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 10px 0 10px;
    height: 30px;
    width: 30px;
    border-radius: 50%;
  }
    
    @media (max-width: 570px) {
      #videos{
        display: flex;
        flex-direction: column;
        height: 85vh;
        width: 100%;
      }
    }