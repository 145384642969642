.test-table-header {
    height: 40px;
    border-radius: 10px;
}

.table-row {
  display: flex;
  flex-direction: row;
  margin-top: 10px;
  margin-bottom: 10px;
}

.test-table-row {
    height: 50px;
    border-radius: 50px;
    box-shadow: 8px 12px 26px rgba(0, 0, 0, 0.12);
}

.test-table-row:hover {
  height: 50px;
  border-radius: 50px;
  background-color: #ccc7fe;
  box-shadow: 8px 12px 26px rgba(0, 0, 0, 0.12);
}
  
  @media (max-width: 570px) {
    #videos{
      display: flex;
      flex-direction: column;
      height: 80vh;
      width: 100%;
    }
  }