.screening-container {
    display: flex;
    background-image: linear-gradient(90deg,#01116E,#000048);
}
.screening-container-loading {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.screening-page {
    background-image: linear-gradient(90deg,#e3eaf8,#fff);
    margin-right: 2%;
    margin-left: 2%;   
    margin-bottom: 2%;
    border-radius: 40px ;
    flex-direction: row;
    color: rgb(0, 0, 0);
    display: flex;
}

.right-screening {
    display: flex;
    width: 50%;
    margin-right: 5%;
    align-items: center;
    justify-content: center;
}

.autism-checkbox {
    width: 80%;
    align-items: center;
    font-size: 1rem;
    display: flex;
}

.autism-checkbox input {
    background-color: #707070;
    height: 20px;
    width: 20px;
}

.autism-checkbox p {
    font-size: 1rem;
    margin-left: 3%;
    margin-right: 3%;
    color: #707070;
    text-align: start;
}

.left-screening {
    margin-bottom: 8%;
    margin-right: 5%;
    margin-top: 8%;
    margin-left: 5%;
    display: flex;
    width: 50%;
    flex-direction: column;
    justify-content: start;
    text-align: start;
}

.left-screening p {
    line-height: 2.1;
    color: rgb(91, 89, 89);
    font-size: 1rem;
}

.left-screening h1 {
    color: rgb(138, 133, 133);
    line-height: 2;
}

@media only screen and (max-width: 720px) {       
    .screening-page {
        flex-direction: column;
        align-items: center;
        text-align: center;
    }   

    .left-screening {
        width: 100%;
        margin-top: 8%;
        margin-bottom: 8%;
    }

    .right-screening {
        width: 100%; 
        margin: 0;   
    }

    .form {
        margin: 0;
        margin-bottom: 8%;
    }

    .gender-radiobox p {
        display: flex;
        justify-content: start;
    }

    .left-screening p {
        margin-right: 3%;
        margin-left: 3%;
    }
    
    .left-screening h1 {
        margin-right: 3%;
        margin-left: 3%;
    }
}
