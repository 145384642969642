.container-slider {
  height: 40vw;
  display: flex;
  flex-direction: column;
  margin: 0 0 ;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  background-image: linear-gradient(90deg,#01116E,#000048);
}

.slide {
  width: 100%;
  height: 90%;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: opacity ease-in-out 0.4s;
}

.slide-image {
  width: 50%;
  left: 0;
  margin: 0 0 0 7.2%;
}

.slide-image img {
  width: 100%;
  /* object-fit: cover; */
  left: 0;
  margin: 0 7.2%;
}

.slide-content {
  width: 50%;
  color: #fff;
  display: flex;
  flex-direction: column;
  margin: 2.2% 7.2% 0 0;
}

.slide-content h1 {
  font-size: 50px;
}

.slide-content p {
  font-size: 30px;
}

.slide-content a {
  color: #ff0073;
  background-color: rgb(255, 255, 255);
  height: 40px;
  width: 170px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 4.2% 0;
  font-size: 15px;
  border-radius: 20px;
  box-shadow: 3px 8px 22px rgba(94, 28, 68, 0.15);
}

.active-anim {
  opacity: 1;
}

.container-dots {
  display: flex;
  flex-direction: row;
  justify-content: center;
  height: 10%;
}

.dot {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 3px solid #f1f1f1;
  margin: 0 5px;
  background: rgb(32, 32, 32);
  cursor: pointer;
}

.dot.active {
  background: #f1f1f1;
}

@media screen and (max-width: 1080px){
  .slide-content h1 {
    font-size: 4.1vw;
  }
  .slide-content p {
    font-size: 2.5vw;
  }
}

@media screen and (max-width: 834px){
  .container-slider {
    height: 190vw;
  }
  .slide {
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
  }
  .slide-image {
    height: 50%;
    width: 100%;
    margin-left: 0;
    margin-right: 0;
  }
  .slide-image img {
    width: 100%;
    margin-left: 0;
    margin-right: 0;
  }
  .slide-content {
    height: 40%;
    width: 80.2%;
    margin: 2.2% 9.9%;
  }
  .slide-content h1 {
    font-size: 7.6vw;
  }
  .slide-content p {
    width: 90%;
    font-size: 4.8vw;
  }
}

@media screen and (max-width: 480px){
  .slide-content h1 {
    font-size: 7.4vw;
  }
  .slide-content p {
    font-size: 4.6vw;
  }
}