.homepage {
    display: flex;
    height: auto;
    flex-direction: column;
}   

.homepage-container {
    background-image: linear-gradient(90deg,#e3eaf8,#fff);
    margin-right: 2%;
    margin-bottom: 2%;;
    margin-left: 2%;
    border-radius: 40px ;
    flex-direction: row;
    color: rgb(0, 0, 0);
    display: flex;
}

.left-wrapper {
    width: 50%;
    margin-left: 5%;
    margin-right: 5%;
    margin-top: 5%;
    margin-bottom: 5%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.left-wrapper img {
    width: 90%;
} 

.right-wrapper {
    width: 50%;
    margin-left: 5%;
    margin-right: 5%;
    margin-top: 5%;
    margin-bottom: 5%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: justify;
}

.login-page-picture {
    width: 100%;
    height: auto;
}

.homepage-main-title {
    display: flex;
    text-align: start;
    padding-right: 15%;
    margin-left: 15%;
    margin-top: 10%;
    color: #707070;
    line-height: 2;
}

.homepage-main-texts {
    text-align: start;
    padding-right: 15%;
    margin-left: 15%;
    color: #707070;
    font-size: 1rem;
    margin-bottom: 30px;
    line-height: 2.1;
}

.push-button-screeningtest {
    text-decoration: none;
    border-radius: 15px;
    width: fit-content;
    background-color: rgb(255, 255, 255);
    color: #ff0073;
    padding: 5px 10px;
    box-shadow: 3px 8px 22px rgba(94, 28, 68, 0.15);
}

.push-button-screeningtest:hover{
    color: #3b38c1;
}

.statics {
    color: #344456;
    background: #fff;
    flex-direction: row;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding-left: 7.2%;
    padding-right: 7.2%;
    padding-bottom: 2%;
}

.statics h1 {
    margin-top: 5%;
    margin-bottom: 5%;
}

.statics-container {
    width: 100%;
    display: grid;
}

.statics-container.statistics-top {
    grid-template-columns: 1fr 1fr 1fr 1fr;
}

.statics-container.statistics-bottom {
    grid-template-columns: 1fr 1fr 1fr;
}

.statics-card {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.stats-circle {
    height: 30vh;
    width: 30vh;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    display: flex;
    background: #221C77;
    border-radius: 50%;
    color: #fff;
    font-size: 80px;
}

.stats-circle h1 {
    font-size: 3rem;
    margin-bottom: 5%;
}

.stats-square {
    height: 12vw;
    width: 20vw;
    font-size: 60px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    display: flex;
    background: #FABA03;
    border-radius: 30px;
}

.stats-square.middle {
    height: 15vw;
    width: 25vw;
}

.stats-square p {
    font-size: 20px;
}

.products-hp {
    /* height: 150vh; */
    display: flex;
    flex-direction: column;
}

.product-hp-container {
    /* height: 65vh; */
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 5vh;
    margin-bottom: 5vh;
}

.product-hp-content {
    /* height: 60vh; */
    min-width: 48%;
    margin-top: 2.5vh;
    margin-bottom: 2.5vh;
}

.product-hp-content h1 {
    font-size: 40px;
    margin: 1.5vh 14.4% 1.5vh 7.2%;
}

.product-hp-content p {
    font-size: 20px;
    line-height: 50px;
    margin: 1.5vh 14.4% 1.5vh 7.2%;
}

.product-hp-image {
    min-width: 48%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #fff;
    border-radius: 0 50px 50px 0;
}

.product-hp-image img{
    width: 40%;
    margin: 5% 0;
}

.mehrobo-partners {
    background-image: linear-gradient(90deg,#e3eaf8,#fff);
    margin-right: 15%;
    margin-left: 15%;
    margin-bottom: 7%;
    border-radius: 40px ;
    justify-content: center;
    align-items: center;
}

.mehrobo-partners h1 {
    text-align: center;
    font-size: 2rem;
    font-weight: 700;
    color: #707070;
    margin-bottom: 3%;
}

.mehrobo-partners div {
    margin-bottom: 3%;
}

.partners-logos {
    justify-content: center;
    align-items: center;
    display: flex;
}

.partners-logos img {
    width: 110px;
    margin: 10px;
}

@media only screen and (max-width: 834px) {  
    .statics-container.statistics-top {
        grid-template-columns: 1fr 1fr;
    }
    .stats-square {
        font-size: 40px;
    }
    .stats-square p {
        font-size: 15px;
    }
    .product-hp-container {
        flex-direction: column;
        align-items: flex-end;
    }
    .product-hp-content {
        width: 90%;
    }
    .product-hp-content h1 {
        font-size: 7.2vw;
        margin: 1.5vh 9.9%;
    }
    .product-hp-content p {
        margin: 1.5vh 9.9%;
        font-size: 3.2vw;
        line-height: 35px;
    }
    .product-hp-image {
        width: 90%;
    }
    .homepage-main-title {
        line-height: 1.9;
    }
    .info {
        line-height: 1.8;
    }
    .mehrobo-partners {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .partners-logos {
        flex-direction: column;
    }

    .partners-logos img{
        width: 200px;
    }
}

@media only screen and (max-width: 480px) {       
    .homepage-container {
        flex-direction: column;
        align-items: center;
        text-align: center;
    }
    .statics-container.statistics-bottom {
        grid-template-columns: 1fr;
    }
    .statics h1 {
        font-size: 25px;
        margin-top: 10%;
        margin-bottom: 10%;
    }
    .stats-circle {
        width: 18vh;
        height: 18vh;
        font-size: 60px;
    }
    .stats-square {
        height: 24vw;
        width: 40vw;
        font-size: 40px;
        margin-bottom: 5%;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        display: flex;
        background: #FABA03;
        border-radius: 15px;
    }
    .stats-square p {
        font-size: 15px;
    }
    .stats-square.middle {
        height: 30vw;
        width: 50vw;
    }
    .right-wrapper {
        width: 100%;
    }

    .left-wrapper {
        width: 100%;
    } 

    .homepage-main-title {
        line-height:1.5 ;
        justify-content: center;
    }
    
    .statics {
        flex-direction: column;
        display: flex;
    }

    .stats-circle-accuracy {
        margin: 10%;
    }

    .mehrobo-partners {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .partners-logos {
        flex-direction: column;
    }

    .partners-logos img{
        width: 200px;
    }
}

  