.screening-test{
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: row;
    background-image: linear-gradient(90deg,#08002d,#3b38c1);
    min-height: 80vh;
}

.screening-test-eng {
    direction: rtl;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: row;
    background-image: linear-gradient(90deg,#08002d,#3b38c1);
}

.test-container {
    flex-direction: column;
    display: flex;
    background-image: linear-gradient(90deg,#e3eaf8,#fff);
    justify-content: center;
    text-align: center;
    border-radius: 20px;
    margin-top: 5%;
    margin-bottom: 5%;
    width: 30%;
    min-width: 400px;
    height: 80%;
}

.container-screening-question {
    height: 250px;
    align-items: center;
    display: flex;
    flex-direction: column;
    margin-bottom: 6%;
}

.screening-test-question {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: end;
    border-radius: 20px;
    margin-top: 4%;
    width: 80%;
    background-color: #fff;
    color: #707070;
    box-shadow: 3px 8px 22px rgba(94, 28, 68, 0.15);
}

.screening-test-question p {
    margin-top: 10%;
    margin-right: 5%;
    margin-left: 5%;
    justify-content: center;
    direction: ltr;
    text-align: center;
    align-items: center;
}


.container-screening-answer {
    align-items: center;
    display: flex;
    flex-direction: column;
    margin-bottom: 6%;
    height: 250px;
}

.screening-answer-option {
    border-color: white;
    color: white;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    border-radius: 45px;
    width: 80%;
    height: 70px;
    margin-top: 1%;
    margin-bottom: 1%;
}

.pushButton-next-question {
    position: relative;
    right: 30px;
    color: black;
    border-radius: 50%;
    background-color: white;
    cursor: pointer;
}

.pushButton-next-question img {
    width: 35px;
    height: 35px;
}

.pushButton-next-question img:hover {
    opacity: 50%;
}

.pushButton-previous-question {
    position: relative;
    left: 30px;
    color: black;
    border-radius: 50%;
    background-color: white;
    cursor: pointer;
}

.pushButton-previous-question img {
    width: 35px;
    height: 35px;
    transform: scaleX(-1);
}

.pushButton-previous-question img:hover {
    opacity: 50%;
}

.question-number {
    color: black;
    justify-content: right;
    text-align: right;
    margin-right: 10px;
    margin-top: 10px;
}

.result-page {
    display: flex;
    height: auto;
    flex-direction: column;
}

.result-page-container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 5%;
    flex-direction: row;
    border-radius: 15px;
}

.result-page-right-wrapper {
    width: 50%;
    display: flex;
    height: 100%;
}

.result-page-left-wrapper {
    width: 50%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.result-answer-title {
    display: flex;
    text-align: start;
    margin-right: 15%;
    margin-left: 15%;
    line-height: 2;
}

.result-answer-text {
    display: flex;
    flex-direction: column;
    padding-right: 15%;
    margin-left: 5%;
    line-height: 2.1;
    font-size: 1.8rem;
    font-weight: 700;
}

.result-page-left-wrapper a {
    text-decoration: none;
    border-radius: 15px;
    margin-right: 15%;
    margin-left: 15%;
    margin-top: 8%;
    margin-bottom: 8%;
    width: fit-content;
    background-color: rgb(255, 255, 255);
    color: #ff0073;
    padding: 5px 10px;
    box-shadow: 3px 8px 22px rgba(94, 28, 68, 0.15);
}

.result-page-left-wrapper a:hover {
    color: #3b38c1;
}

.result-page-left-wrapper a:hover {
    color: #3b38c1;
}

@media only screen and (max-width: 520px) {
    .test-container {
        min-width: 330px;
    }

    .result-answer {
        font-size: 2rem;
        width: 90%;
    }

    .result-page-container {
        flex-direction: column;
    }

    .result-page-left-wrapper {
        width: 100%;
        margin-top: 8%;
        margin-bottom: 0;
    }

    .result-page-right-wrapper {
        width: 100%;
    }
}