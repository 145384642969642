@font-face {
  font-family: IRANYekanThin;   /*Can be any text*/
  src: local("AirbnbCerealLight"),
    url("./fonts/IRANYekan/IRANYekanWebThin.ttf") format("truetype");
}
@font-face {
  font-family: IRANYekanLight;   /*Can be any text*/
  src: local("AirbnbCerealLight"),
    url("./fonts/IRANYekan/IRANYekanWebLight.ttf") format("truetype");
}
@font-face {
  font-family: IRANYekanRegular;   /*Can be any text*/
  src: local("AirbnbCerealLight"),
    url("./fonts/IRANYekan/IRANYekanWebRegular.ttf") format("truetype");
}

body {
  margin: 0;
  /* color: #707070; */
  /* color: #344456; */
  color: #fff;
  background-image: linear-gradient(90deg,#01116E,#000048);
  /* background: #f0f0f0; */
  /* background: #fff; */
}

html {
  /* font-family: Vazir; */
  font-family: IRANYekanRegular;
}

a, a:focus, a:hover {
  text-decoration: none;
  border: none;
  cursor: pointer;
  color: #fff
  /* color: #344456; */
}

.line-through {
  text-decoration: line-through;
}

table, th, td {
  border: 1px solid black;
}

.btn-default {
  height: 50px;
  width: 80px;
  display: flex;
  color: white;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  padding-right: 3%;
  padding-left: 3%;
  border-radius: 50px;
  box-shadow: 8px 12px 26px rgba(0, 0, 0, 0.12);
}

.input-default {
  height: 50px;
  width: 80px;
  border-radius: 25px;
  background-color: #E3EAF8;
  color: #707070;
}

.mode-bg-2 {
  background-image: linear-gradient(to right, #3B38C1 , #1B9FF1);
}

.mode-bg-3 {
  background-image: linear-gradient(to right, #0C7C59 , #5AFF15);
}

.mode-bg-4 {
  background-image: linear-gradient(to right, #8D1D57 , #DD0B86);
}

.mode-bg-5 {
  background-image: linear-gradient(to right, #1B9FF0 , #7FC5F4);
}

.mode-bg-6 {
  background-image: linear-gradient(to right, #C60001 , #F80001);
}

.mode-bg-7 {
  background-image: linear-gradient(to right, #9E0001 , #FF3738);
}

.mode-bg-8 {
  background-image: linear-gradient(to right, #E3EAF8 , #FFFFFF);
}

.mode-bg-9 {
  background-color: #fff;
}

.mode-bg-10 {
  background-image: linear-gradient(to right, #C26D38 , #C23838);
}

.mode-bg-11 {
  background-image: linear-gradient(to right, #03aba0 , #8dc63f);
}

.mode-text-0 {
  color: #fff;
}

.mode-text-1 {
  color: #a4a4a4;
}

.mode-text-2 {
  color: #ddd;
}

.mode-text-3 {
  color: #bbb;
}

.mode-text-4 {
  color: #555;
}

.mode-text-5 {
  color: #c3c3ce;
}

.mode-text-7 {
  color: #555;
}

.mode-text-9 {
  color: #000;
}

.mode-text-10 {
  color: #11FF00;
}

.mode-text-11 {
  color: #FF0000;
}

.mode-text-12 {
  color: #FFA500;
}

.mode-text-13 {
  color: #8D1D57;
}

.mode-text-14 {
  color: #1B9FF0;
}

.mode-text-15 {
  color: #8dc63f;
}

.font-light {
  font-family: Vazir-Light, sans-serif;
}

.font-medium {
  font-family: Vazir-Medium, sans-serif;
}

.font-11 {
  font-size: 0.6875em;;
}

.font-12 {
  font-size: 0.75em;;
}

.font-13 {
  font-size: 0.8125em;
}

.font-14 {
  font-size: 0.875em;
}

.font-15 {
  font-size: 0.9375em;
}

.font-18 {
  font-size: 1.125em;
}

.mt-0 {
  margin-top: 0rem
}

.mt-1 {
  margin-top: 0.25rem
}

.mt-2 {
  margin-top: 0.5rem;
}

.mt-3 {
  margin-top: 1rem;
}

.mt-4 {
  margin-top: 1.5rem;
}

.ml-2 {
  margin-left: 0.5rem;
}

.mb-0 {
  margin-bottom: 0rem;
}

.mb-1 {
  margin-bottom: 0.25rem;
}

.mb-2 {
  margin-bottom: 0.5rem;
}

.mb-3 {
  margin-bottom: 1rem;
}

.mb-4 {
  margin-bottom: 1.5rem;
}

.mr-3 {
  margin-right: 1rem;
}

.mt-3 {
  margin-top: 1rem;
}

.mt-4 {
  margin-top: 1.5rem;
}

.my-4 {
  margin-top: 1.5rem;
}

.pr-2 {
  padding-right: 0.5rem;
}

.pl-2 {
  padding-left: 0.5rem;
}

.pb-4 {
  padding-bottom: 1.5rem;
}

.pr-4 {
  padding-right: 1.5rem;
}

.pr-4 {
  padding-left: 1.5rem!important;
}

.py-4 {
  padding-top: 1.5rem;
}

.d-ltr {
  direction: ltr;
}

.d-rtl {
  direction: rtl;
}

.d-flex {
  display: flex;
}

.d-block {
  display: block;
}

.d-inline-block {
  display: inline-block;
}

.flex-column {
  flex-direction: column;
}

.align-items-center {
  align-items: center;
}

.justify-content-center {
  -webkit-box-pack: center!important;
  -ms-flex-pack: center!important;
  justify-content: center!important;
}

.justify-content-between {
  -webkit-box-pack: justify!important;
  -ms-flex-pack: justify!important;
  justify-content: space-between!important;
}

.justify-content-around {
  -webkit-box-pack: justify!important;
  -ms-flex-pack: justify!important;
  justify-content: space-around!important;
}

.justify-content-end {
  -webkit-box-pack: end!important;
  -ms-flex-pack: end!important;
  justify-content: end!important;
}

.w-100 {
  width: 100%!important;
}

.w-90 {
  width: 90%!important;
}

.w-50 {
  width: 50%!important;
}

.w-35 {
  width: 35%!important;
}

.w-15 {
  width: 15%!important;
}

.h-100 {
  height: 100%!important;
}

.h-90 {
  height: 90%!important;
}

.h-50 {
  height: 50%!important;
}

.h-35 {
  height: 35%!important;
}

.h-15 {
  height: 15%!important;
}

.h-100-30px {
  height: calc(100% - 30px)!important;
}

.h-100-60px {
  height: calc(100% - 60px)!important;
}

.container-fluid {
  width: 100%;
}

.row {
  display: flex;
  flex-wrap: wrap;
}

.col-12 {
  -webkit-box-flex: 0;
  flex: 0 0 100%;
  max-width: 100%;
}

.boxes {
  display: grid;
  grid-template-columns: repeat(4, 175px);
  grid-template-rows: repeat(auto-fill, 178px);
  grid-gap: 35px;
}

.boxes .box {
  background-color: #fff;
  min-width: 175px;
  max-height: 178px;
  min-height: 178px;
  border-radius: 9px;
  box-shadow: 0 0 13px 0 rgb(0 0 0 / 5%);
  /* position: relative; */
}

.boxes .box .span {
  line-height: 1.5;
}

.panel {
  background-color: #fff;
  border-radius: 9px;
  box-shadow: 0 0 13px 0 rgb(0 0 0 / 5%);
  width: 100%;
}

.form {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 15%;
  margin-bottom: 15%;
  background-image: linear-gradient(90deg,#eaf0ff,#f4f7ff);
  display: flex;
  flex-direction: column;
  margin-left: 15%;
  border-radius: 35px;
  width: 100%;
  box-shadow: 24px 24px 42px rgba(94, 28, 68, 0.15);
}

.input-box-childName {
  width: 80%;
  border-radius: 25px;
  background-color: #E3EAF8;
  color: #707070;
  height: 40px;
  margin-top: 10%;
}

.input-box-childName[type=text] {
  padding: 12px 20px;
  box-sizing: border-box;
}

.input-box-mobileNumber {
  width: 80%;
  border-radius: 25px;
  background-color: #E3EAF8;
  color: #707070;
  height: 40px;
  margin-top: 20px;
  padding-left: 5px;
}

.input-box-mobileNumber::-webkit-outer-spin-button,
.input-box-mobileNumber::-webkit-inner-spin-button {
-webkit-appearance: none;
margin: 0;
}

.input-box-mobileNumber[type=number] {
  -moz-appearance: textfield;
  padding: 12px 20px;
  box-sizing: border-box;
}

.drop-down {
  width: 80%;
  border-radius: 10px;
  background-color: #E3EAF8;
  color: #707070;
  height: 40px;
  margin-top: 15px;
}

.radiobox-section {
  width: 80%;
  font-size: 1.1rem;
  color: #707070;
  margin-left: 6%;
  margin-right: 6%;
  margin-bottom: 3%;
}

.radio-buttons-box {
  display: flex;
  align-items: center;
}

.radio-button-1 input {
  width: 20px;
  height: 20px;
  margin-right: 5px;
  margin-left: 5px;
}

.radio-button-2 {
  margin-left: 8%;
  margin-right: 8%;
}

.radio-button-2 input {
  width: 20px;
  height: 20px;
  margin-right: 5px;
  margin-left: 5px;
}


.field-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-left: 5%;
  margin-right: 5%;
}

.price-field {
  display: flex;
  flex-direction: column;
}

.error-text {
  color: red;
  height: 1rem;
  font-size: .786rem;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: start;
  width: 75%;
}

.error-text-main {
  color: red;
  height: 1rem;
  font-size: .786rem;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.price-field-amount {
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
}

.price-field-amount p {
  width: 80%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 25px;
  background-color: #E3EAF8;
  color: #707070;
  height: 50px;
  margin-top: 15px;
}

.price-field-discount {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 25px;
  background-color: #E3EAF8;
  color: #707070;
  height: 50px;
  margin-top: 15px;
}

.price-field-discount button {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: white;
  cursor: pointer;
  margin-left: 5px;
  margin-right: 5px;
}

.price-field-discount input {
  color: #707070;
  background-color: #E3EAF8;
  border: none;
  width: 50%;
  height: 30px;
  font-size: 1.1rem;
  border-radius: 10px;
  margin-left: 5px;
  margin-right: 5px;
}

.control-button {
  height: 50px;
  border-radius: 25px;
  width: auto;
  background-image: linear-gradient(90deg,#1B9FF1,#3B38C1);
  margin: 0 3% 3% 3%;
  color: white;
  cursor: pointer;
}

.control-button:hover {
  opacity: 80%;
}

.modal-background {
  height: 100%;
  width: 100%;
  background-color: rgba(0,0,0,.79);
  position: fixed;
  top: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 15px;
}

.modal-container {
  width: 600px;
  height: auto;
  border-radius: 12px;
  background-image: linear-gradient(90deg,#e3eaf8,#fff);
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  display: flex;
  flex-direction: column;
  padding: 25px;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.modal-title {
  flex: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.4rem;
  text-align: center;
  color: #707070;
}

.modal-drop-down {
  border-radius: 25px;
  background-color: #E3EAF8;
  color: #707070;
  height: 40px;
  width: 200px;
  margin-bottom: 10%;
}

.modal-footer {
  flex: 20%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-footer button {
  width: 150px;
  height: 45px;
  margin: 10px;
  border: none;
  background-image: linear-gradient(to right, #3B38C1 , #1B9FF1);
  color: white;
  border-radius: 8px;
  font-size: 20px;
  cursor: pointer;
}

.modal-error {
  color: red;
  height: 1rem;
  font-size: .986rem;
  text-align: center;
}

@media (max-width: 767.98px) {
  .btn-default {
    width: 50px;
  }
  .d-none-mobile {
    display: none;
  }
}

@media (max-width: 480px) {
  .modal-container {
      width: 300px;
  }
}

@page {
  size: auto;
  margin: 10mm 0 10mm 0;
}