.receipt-container {
    position: relative;
    width: 100%;
    min-height: 100vh;
    overflow: hidden;
}

.receipt-verify-result {
    display: flex;
    flex-direction: column;
    margin: 20%;
    font-family: Vazir, serif;
    justify-content: center;
    align-items: center;
}

.receipt-verify-result a {
    margin: 5% auto;
}

.receipt-button {
    font-family: Vazir, sans-serif;
    border-radius: 50px;
    cursor: pointer;
    background-color: #5995fd;
    color: #fff;
    text-decoration: none;
    padding: 10px 20px;
    font-weight: 600;
}

.receipt-button:hover{
    background-color: #4d84e2;
}