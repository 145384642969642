.access-modal-background {
    height: 100%;
    width: 100%;
    background-color: rgba(0,0,0,.79);
    position: fixed;
    top: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 15px;
}

.access-modal-container {
    width: 400px;
    height: 150px;
    border-radius: 20px;
    background-image: linear-gradient(90deg,#fff,#E3EAF8);
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    display: flex;
    flex-direction: column;
    padding: 25px;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.access-modal-container button {
    width: 20px;
    height: 20px;
}

.access-modal-title {
    flex: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2rem;
    font-weight: 600;
    text-align: center;
    color: #000000;
}

.access-modal-container button {
    width: 150px;
    height: 45px;
    margin: 10px;
    border: none;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    background-color: #FFFFFF;
    color: #11FF00;
    border-radius: 30px;
    font-size: 17px;
    cursor: pointer;
}
