.panel-left {
    min-height: calc(100vh - 30px);
}

.panel-container {
    display: grid;
    grid-template-columns: 210px auto;
    padding: 30px 30px 0 30px;
    grid-gap: 50px;
    background-image: linear-gradient(to right, #08002D , #3B38C1);
    min-height: 100vh;
    color: #fff;
}

.fa-bars {
    display: none;
}

.information-container {
    display: flex;
    width: 100%;
    margin-top: 10%;
    font-family: Vazir, sans-serif;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.dashboard-container {
    display: flex;
    width: 100%;
    margin-top: 10%;
    font-family: Vazir, sans-serif;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.dashboard-container a{
    display: flex;
    width: 150px;
    height: 50px;
    justify-content: center;
    align-items: center;
    text-align: center;
    text-decoration: none;
    border: none;
    outline: none;
    border-radius: 50px;
    cursor: pointer;
    background-color: #5995fd;
    color: #fff;
    font-weight: 600;
    margin: 10px 0;
}

@media (max-width: 1200px) {

    .panel-right {
        display: none;
    }

    .panel-right-responsive {
        position: fixed;
        width: 210px;
        top: 0;
        right: 40px;
        z-index: 99;
        -webkit-transition: right .3s ease;
        transition: right .3s ease;
    }

    .panel-container {
        grid-template-columns: 100%;
        padding: 15px 14px;
        grid-gap: 25px;
    }

    .fa-bars {
        display: flex;
        color: #FFFFFF;
        font-size: large;
        padding-left: 20px;
    }
}

@media (max-width: 991.98px) {
    .boxes {
        grid-template-columns: repeat(4,1fr);
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
    }

    .boxes .box {
        font-size: 14px;
    }
}

@media (max-width: 767.98px) {
    .boxes {
        grid-template-columns: 48% 48%;
        grid-gap: 20px;
        grid-template-rows: repeat(auto-fill,178px);
    }

    .boxes .box {
        min-width: auto;
    }
}