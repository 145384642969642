footer {
    display: flex;
    flex-direction: column;
    background-image: linear-gradient(90deg,#000048,#08002D);
    color: #fff;
    padding: 0 7.2%;
}

.footer-container {
    flex-direction: row;
    display: flex;
}

.footer-container-right {
    width: 50%;
    color: white;
}

.footer-container-right h1 {
    font-size: 1.2rem;
    font-weight: 700;
    color: #518ef7;
}

.footer-container-left {
    width: 50%;
    text-align: center;
    justify-content: start;
}

.adress-mehrobo-instagram {
    margin-top: 10%;
    margin-left: 2%;
    margin-right: 2%;
    display: flex;
    color: white;
    justify-content: end;
    align-items: center;
    flex-direction: row;
    text-align: center;
    text-decoration: none;
}

.adress-mehrobo-instagram img {
    border-radius: 50%;
    width: 50px;
    height: auto;
    padding-left: 5px;
}

.adress-mehrobo-instagram p {
    margin-left: 3%;
}

.section-all-rights-reserved {
    font-size: 0.8rem;
    background-color: #333;
    text-align: center;
}

@media only screen and (max-width: 520px) { 
    .footer-container {
        flex-direction: column;
        justify-content: center;
        display: flex;
    }

    .footer-container-left{
        width: 100%;
    }

    .footer-container-right{
        width: 100%;
    }
}