.music-therapy-container {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    background-image: linear-gradient(90deg,#08002d,#3b38c1);
}
.music-therapy-loading {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.music-therapy-page {
    background-image: linear-gradient(90deg,#e3eaf8,#fff);
    margin-right: 2%;
    margin-left: 2%;   
    margin-bottom: 2%;
    border-radius: 40px ;
    flex-direction: row;
    color: rgb(0, 0, 0);
    display: flex;
}

.right-music-therapy {
    display: flex;
    width: 50%;
    margin-right: 5%;
    align-items: center;
    justify-content: start;
}

.name-familyname {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 80%;
}

.gender-radiobox p {
    display: flex;
    justify-content: start;
}

.price-field-dropdown{
    background-color: #E3EAF8;
    color: #707070;
    text-align: center;
    margin-top: 10px;
    border-radius: 10px;
}

.left-music-therapy {
    margin-bottom: 8%;
    margin-right: 5%;
    margin-top: 8%;
    margin-left: 5%;
    display: flex;
    width: 50%;
    flex-direction: column;
    justify-content: start;
    text-align: start;
}

.left-music-therapy p {
    line-height: 2.1;
    color: rgb(91, 89, 89);
    font-size: 1rem;
}

.left-music-therapy h1 {
    color: rgb(138, 133, 133);
    line-height: 2;
}

@media only screen and (max-width: 720px) {       
    .music-therapy-page {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        text-align: center;
    }   

    .left-music-therapy {
        width: 100%;
        margin-top: 8%;
        margin-bottom: 8%;
    }

    .right-music-therapy {
        width: 100%;
        display: flex;
        margin: 0;
    }

    .form {
        margin: 0;
        margin-bottom: 8%;
    }

    .gender-radiobox p {
        display: flex;
        justify-content: start;
    }

    .left-music-therapy p {
        margin-left: 3%;
        margin-right: 3%;
    }
    
    .left-music-therapy h1 {
        margin-left: 3%;
        margin-right: 3%;
    }
}
