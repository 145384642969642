.evaluations-add-btn {
  background-color: #e5fcf5;
  width: 55px;
  height: 55px;
  border-radius: 9px;
  -webkit-transition: all .3s ease-in;
  transition: all .3s ease-in;
}

.evaluations-add-btn span {
  -webkit-transition: all .3s ease-in;
  transition: all .3s ease-in;
  color: #3ecc97;
  font-size: 35px;
  font-weight: 300;
}

.evaluations-sub-btn {
  background-color: #e5fcf5;
  width: 55px;
  height: 54px;
  border-radius: 9px;
  -webkit-transition: all .3s ease-in;
  transition: all .3s ease-in;
}

.evaluations-sub-btn span {
  -webkit-transition: all .3s ease-in;
  transition: all .3s ease-in;
  color: #3ecc97;
  font-size: 35px;
  font-weight: 300;
}

.test-table-header {
    height: 40px;
    border-radius: 10px;
}

.table-row {
  display: flex;
  flex-direction: row;
  margin-top: 10px;
  margin-bottom: 10px;
}

.test-table-row {
    height: 50px;
    border-radius: 50px;
    box-shadow: 8px 12px 26px rgba(0, 0, 0, 0.12);
}

.test-table-row:hover {
  height: 50px;
  border-radius: 50px;
  background-color: #ccc7fe;
  box-shadow: 8px 12px 26px rgba(0, 0, 0, 0.12);
}
  
  @media (max-width: 570px) {
    #videos{
      display: flex;
      flex-direction: column;
      height: 80vh;
      width: 100%;
    }
  }