.result-modal-background {
    height: 100%;
    width: 100%;
    background-color: rgba(0,0,0,.79);
    position: fixed;
    top: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 15px;
}

.result-modal-container {
    width: 550px;
    height: 80%;
    border-radius: 20px;
    background-image: linear-gradient(90deg,#fff,#E3EAF8);
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    display: flex;
    flex-direction: column;
    padding: 25px;
    margin: 20px;
    justify-content: center;
    text-align: center;
    align-items: center;
}

.result-modal-container button {
    width: 150px;
    height: 45px;
    margin: 10px;
    border: none;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    background-color: #FFFFFF;
    color: #11FF00;
    border-radius: 30px;
    font-size: 17px;
    cursor: pointer;
}

.results-print {
    text-align: center;
    align-items: center;
    direction: rtl;
    overflow-y: auto;
}

.result-header {
    display: flex;
    width: 100%;
    flex-direction: row;
    border-radius: 15px;
    margin-bottom: 20px;
}

.result-spec-table {
    display: flex;
    justify-content: center;
    flex-direction: row;
    margin: 20px;
}

.result-spec-left-wrapper {
    width: 50%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: start;
}

.result-spec-right-wrapper {
    width: 50%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: start;
}

.result-modal-title {
    flex: 50%;
    font-size: 1.0rem;
    font-weight: 600;
    margin-right: 20px;
    text-align: center;
}

.result-table {
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin: 20px;
    border-style: solid;
    border-color: #707070;
    border-width: 1px;
}

.table-result-items {
    display: flex;
    align-items: center;
    height: 35px;
    color:#707070;
}

.result-item-title {
    margin-right: 10px;
    text-align: start;
    width: 85%;
}

.result-item-stats {
    width: 15%;
    padding-top: 6.2px;
    padding-bottom: 6.2px;
}

@media print {
    .results-print {
        overflow-y: visible;
        display: 'block';
    }
    .result-table {
        width: 90%;
        page-break-inside: avoid;
    }
    .result-table-items {
        page-break-inside:avoid;
        page-break-after:auto;
    }
}