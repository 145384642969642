.container {
    position: relative;
    width: 100%;
    min-height: 100vh;
    background-color: #fff;
    overflow: hidden;
}

.container::before {
    content: '';
    position: absolute;
    width: 2000px;
    height: 2000px;
    border-radius: 50%;
    background: linear-gradient(to right, #08002d 0, #08002d 60%, #231D7A 100%);
    top: -10%;
    right: 48%;
    transform: translateY(-50%);
}

.forms-container {
    /* position: absolute; */
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
}

.signin-signup {
    width: 100%;
    /* z-index: 5; */
}

.signin-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* padding: 0 5rem; */
    overflow: hidden;
}

.signin-title {
    font-size: 1.2rem;
    font-family: Vazir-Bold, sans-serif;
    color: #444;
    margin-bottom: 10px;
}

.input-field {
    max-width: 380px;
    width: 100%;
    height: 55px;
    font-family: Vazir, sans-serif;
    background-color: #f0f0f0;
    margin: 10px 0;
    border-radius: 55px;
    display: grid;
    grid-template-columns: 15% 85%;
}

.input-field svg {
    margin: auto;
    text-align: center;
    line-height: 55px;
    color: #acacac;
    font-size: 1.1rem;
}

.input-field input{
    background: none;
    outline: none;
    border: none;
    line-height: 1;
    font-weight: 600;
    font-size: 1.1rem;
    color: #333;
}

.input-field input::placeholder {
    color: #aaa;
    font-weight: 500;
}

.btn {
    width: 150px;
    height: 49px;
    font-family: Vazir, sans-serif;
    border: none;
    outline: none;
    border-radius: 49px;
    cursor: pointer;
    background-color: #5995fd;
    color: #fff;
    font-weight: 600;
    margin: 10px 0;
    transition: .5s;
}

.btn:hover{
    background-color: #4d84e2;
}

.error-message {
    max-width: 380px;
    width: 100%;
    height: 20px;
}

.error-message label {
    font-size: 0.8rem;
    color: #b2001a;
}

.panels-container {
    position: relative;
    width: 100%;
    height: 100%;
    font-family: Vazir;
    top: 0;
    left: 0;
    display: grid;
    grid-template-columns: 50% 50%;
}

.signin-panel {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: space-around;
    text-align: center;
}

.left-panel {
    padding: 3rem 17% 2rem 12%;
}

.right-panel {
    padding: 3rem 12% 2rem 17%;
}

.signin-panel .content {
    color: #fff;
}

.signin-panel h3 {
    font-weight: 600;
    line-height: 1;
    font-size: 1.5rem;
}

.signin-panel p {
    font-size: 0.95rem;
    padding: 0.7rem 0;
}

.more-apps-svg-image {
    width: 100%;
}

@media (max-width: 870px) {

    .container::before {
        width: 1500px;
        height: 1500px;
        left: 30%;
        bottom: 68%;
        background: linear-gradient(to right, #08002d 0, #08002d 33%, #3935B9 87%);
        transform: translateX(-50%);
        right: initial;
        top: initial;
    }

    .signin-signup {
        width: 100%;
    }

    .panels-container {
        /* z-index: 10; */
        grid-template-columns: 100%;
        grid-template-rows: 50% 50%;
    }

    .signin-panel {
        flex-direction: row;
        justify-content: space-around;
        align-items: center;
        padding: 0.5rem 8% 2.5rem;
    }

    .left-panel {
        grid-row: 1 / 2;
    }

    .right-panel {
        grid-row: 2 / 3;
    }

    .signin-panel .content {
        margin: auto;
        padding-right: 14%;
        padding-bottom: 5rem;
    }

    .signin-panel h3 {
        font-size: 1.2rem;
    }

    .signin-panel p {
        font-size: 0.8rem;
        padding: 0.5rem 0;
    }

    .more-apps-svg-image {
        width: auto;
        height: 100%;
    }

    .right-panel .content, .right-panel .more-apps-svg-image {
        transform: translateY(300px);
    }
}

@media (max-width: 570px) {
    .panels-container {
        grid-template-rows: 28% 72%;
    }
    .signin-form {
        padding: 0 1.5rem;
    }

    .more-apps-svg-image {
        display: none;
    }

    .panel .content {
        margin: auto;
        padding: 0.5rem 1rem 5rem;
    }

    .container::before {
        bottom: 72%;
        left: 50%;
        background: linear-gradient(to right, #08002d 0, #08002d 36%, #3b38c1 65%);
    }
}