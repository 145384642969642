.video-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
  }
  
  #videos{
    display: flex;
    flex-direction: column;
    position: relative;
    height: 80vh;
    justify-content: center;
  }
  
  /* #videos{
    position: relative;
    height: 85vh;
    width: 100%;
    margin: auto;
    align-self: flex-start;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(440px, 1fr));
    justify-items: center;
    align-items: center;
  } */
  
  .vid{
    position: relative;
    width: 95%;
    height: 95%;
    max-width: 75vw;
    /* max-height: 40vh; */
    margin: auto;
    background-color:black;
    border-width: 1px;
    border-color: #38373A;
    border-style: solid;
  }
  
  .controls {
    position: absolute;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 500px;
    max-width: 60vw;
  }
  
  .controls p {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 10px;
    margin-right: 10px;
    min-width: 60px;
    min-height: 60px;
    padding: 10px;
    cursor: pointer;
    background: linear-gradient(to right, #C60001 , #F80001);
    color: #F7F7F7;
    border-width: 1px;
    border-color: #F7F7F7;
    border-style: solid;
    border-radius: 50%;
  }
  
  .controls p.on{
    background: linear-gradient(to right, #162E96 , #1C39BB);
    border-width: 1px;
    border-color: #38373A;
    border-style: solid;
  }
  
  .session-container {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100%;
    height: -moz-max-content;
    min-height: 50vh;
    align-items: center;
    justify-content: center;
  }
  .session-title-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 10vh;
  }

  .session-title-wrapper h1{
    
  }

  .videocall-wrapper {
    position: flex;
    flex-direction: column;
    height: 100vh;
    width: 100%;
  }

  .videocall {
    position: relative;
    display: flex;
    justify-content: center;
    height: 80vh;
  }
  
  .buttons-wrapper {
    display: flex;
    justify-content: center;
    height: 10vh;
  }
  
  @media (max-width: 570px) {
    .vid {
      max-width: none;
    }
    #videos{
      display: flex;
      flex-direction: column;
      height: 80vh;
      width: 100%;
    }
    .controls p {
      min-width: 40px;
      min-height: 40px;
      font-size: 15px;
    }

    .controls p.on {
      min-width: 40px;
      min-height: 40px;
      font-size: 15px;
    }
  }