.dashboard-title-container {
    width: 100%;
    padding-top: 0.25rem;
    margin-bottom: 1.5rem;
}

.panel-home-page-content {
    display: grid;
    padding-bottom: 20px;
    grid-gap: 37px;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-areas: "item-1 item-2 item-3"
                         "item-4 item-5 item-3";
}

.home-item-1 {
    grid-area: item-1;
    height: 80px;
}

.home-item-2 {
    grid-area: item-2;
    height: 80px;
}

.home-item-3 {
    grid-area: item-3;
    min-height: 80px;
}

.home-item-4 {
    grid-area: item-4;
    height: 80px;
}

.home-item-5 {
    grid-area: item-5;
    height: 80px;
}

.panel-card {
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 0 13px 0 rgb(0 0 0 / 5%);
}

.item-content {
    display: flex;
    align-items: center;
    justify-content: start;
}

.label-item-icon {
    width: 48px;
    height: 48px;
    border-radius: 100%;
}

.label-item-description {
    height: 40px;
    justify-content: space-between;
    align-items: flex-start;
}

.chart-data {
    direction: ltr;
    grid-area: chart;
    width: 100%;
    height: 40vh;
}



@media (max-width: 850px) {
    .panel-home-page-content {
        grid-template-columns: 1fr;
        grid-template-areas:
            "item-1"
            "item-2"
            "item-3"
            "item-4"
            "item-5";
    }
    .home-item-3 {
        grid-area: item-3;
        min-height: 120px;
    }
}

@media print {
    .chart-data {
        height: auto;
    }
}