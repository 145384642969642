/* styles.css */

.landing-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: #0e2a47;
}

.content-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 0 2rem;
  flex: 1;
}

.image-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
}

.product-image {
  width: 100%;
  max-width: 800px;
  margin-bottom: 2rem;
}

.landing-heading {
  font-size: 3rem;
  font-weight: bold;
  margin-bottom: 1rem;
  color: white;
  text-align: center;
}

.landing-text {
  font-size: 1.5rem;
  margin-bottom: 2rem;
  color: white;
  text-align: center;
}

.cta-button {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  font-weight: bold;
  color: white;
  border: none;
  border-radius: 0.5rem;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;
  background-image: linear-gradient(45deg, #4CAF50, #2E7D32);
  width: 150px;
  max-width: 100%;
  margin: 0 auto;
}

.cta-button:hover {
  background-image: linear-gradient(45deg, #3e8e41, #1B5E20);
}

@media only screen and (min-width: 768px) {
  /* Tablets and desktops */
  .landing-container {
    flex-direction: row;
  }

  .content-container {
    width: 50%;
    max-width: 50%;
  }

  .image-container {
    width: 50%;
    max-width: 50%;
  }

  .landing-heading {
    text-align: left;
  }

  .landing-text {
    text-align: left;
  }

  .product-image {
    margin-bottom: 0;
  }

  .cta-button {
    margin: 0 0;
  }
}