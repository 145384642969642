.main-body {
    text-align: center;
}

.about-title {
    display: flex;
    color: #FFFFFF;
    justify-content: center;
    flex-direction: column;
    margin-top: 40px;
    margin-bottom: 10px;
}

.about-title h1 {
    font-size: 40px;
    line-height: 20px;
}

.about-title h3 {
    font-family: IRANYekanThin;
}

.about-body {
    display: flex;
    height: 80%;
    justify-content: center;
    color: #fff;
    margin-top: 5%;
    margin-bottom: 5%;
}

.cards-container {
    display: flex;
    flex-direction: row;
    margin-bottom: 3%;
}

.description-container {
    display: flex;
    margin-bottom: 3%;
}

.description-container p {
    line-height: normal;
    padding: 0 20%;
}

.profile-card {
    width: 100%;
}

.profile-card > img {
    display: flex;
    width: 100%;
}

.about-contact {
    display: flex;
    color: #FFFFFF;
    justify-content: center;
}

@media only screen and (max-width: 834px) {  
    .about-title h1 {
        font-size: 30px;
        line-height: 15px;
    }
    .about-title h3 {
        font-size: 14px;
    }
}

@media only screen and (max-width: 480px) {
    .about-title h1 {
        font-size: 20px;
        line-height: 10px;
    }
    .about-title h3 {
        font-size: 10px;
    }
}