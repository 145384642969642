.product {
    display: flex;
    height: auto;
    flex-direction: column;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    margin-top: 3%;
}

.product-container {
    /* background-image: linear-gradient(90deg,#e3eaf8,#fff); */
    min-height: 80vh;
    margin-right: 2%;
    margin-bottom: 2%;;
    margin-left: 2%;
    border-radius: 40px ;
    flex-direction: row;
    color: rgb(0, 0, 0);
    display: flex;
}

.right-wrapper-clinic {
    width: 60%;
}

.left-wrapper-clinic{
    display: flex;
    justify-content: center;
    align-items: right;
    background-image: linear-gradient(90deg,#eaf0ff,#f4f7ff);
    display: flex;
    flex-direction: column;
    border-radius: 35px;
    padding: 3%;
    margin-left: 3%;
    width: 40%;
    box-shadow: 24px 24px 42px rgba(94, 28, 68, 0.15);
}

.left-wrapper-clinic h1 {
    font-size: 2.5rem;
}

.left-wrapper-clinic h2 {
    font-size: 1.18rem;
}

.input-container {
    display: flex;
}

.price-container {
    display: flex;
}

@media only screen and (max-width: 720px) {       
    .product-container {
        flex-direction: column;
        align-items: center;
    }

    .left-wrapper-clinic {
        width: 90%;
        margin-top: 50px;
    }

    .right-wrapper-clinic {
        width: 100%;
    }
}