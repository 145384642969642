.users-modal-title {
    display: flex;
    justify-content: start;
    align-items: center;
    margin: 10px;
}

.users-input {
    width: 80%;
    margin: 0 5px 0 5px;
    border-radius: 10px;
    background-color:#fff;
    color: #707070;
    height: 40px;
}

.users-input[type=text] {
    -moz-appearance: textfield;
    padding: 12px 20px;
    box-sizing: border-box;
  }

.users-input[type=number] {
    -moz-appearance: textfield;
    padding: 12px 20px;
    box-sizing: border-box;
}

.users-input[type=password] {
    -moz-appearance: textfield;
    padding: 12px 20px;
    box-sizing: border-box;
}
